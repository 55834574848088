import ELECTION_DAY from "../enums/election-day.json";

// =====      RESET       =====
// =====SECOND PHASE START=====

export const ADULT_DATE = "2025-03-10T00:00:00";

export const REGISTRATION_DEADLINE = "2025-03-11T00:00:00";

export const ELECTION_STAGES = [
  {
    type: ELECTION_DAY.ADVANCE_MUNICIPALITY,
    start: "2025-03-11T00:00:00",
    end: "2025-03-13T00:00:00"
  },
  {
    type: ELECTION_DAY.ADVANCE_SPECIAL,
    start: "2025-03-12T00:00:00",
    end: "2025-03-14T00:00:00"
  },
  {
    type: ELECTION_DAY.ADVANCE_HOME,
    start: "2025-03-14T00:00:00",
    end: "2025-03-15T00:00:00"
  },
  {
    type: ELECTION_DAY.ELECTION_DAY,
    date: "2025-03-16T00:00:00"
  }
];

// =====SECOND PHASE END=====
