import React from "react";
import PropTypes from "prop-types";

import CONTACT from "../../../../content/contact.json";

import logoImg from "../../../assets/images/logo.svg";
import IconPhone from "../../../assets/icons/phone.svg";
import IconMail from "../../../assets/icons/mail.svg";

import ButtonLink from "../../button-link";
import { useTranslations } from "../../../hooks/translations";
import LocalizedLink from "../../localized-link";
import SocialLinks from "../../social-links";

import styles from "./footer.module.scss";

const NavigationLink = ({ url, label }) => (
  <ButtonLink to={url} className={`bp-navigation ${styles.navLink}`}>
    {label}
  </ButtonLink>
);

NavigationLink.propTypes = {
  label: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired
};

const NavigationColumn = ({ label, links }) => (
  <div className={styles.navColumn}>
    <h3>{label}</h3>
    <ul className={styles.navList}>
      {links.map(link => (
        <li key={link.key}>
          <NavigationLink url={link.url} label={link.label} />
        </li>
      ))}
    </ul>
  </div>
);

NavigationColumn.propTypes = {
  label: PropTypes.string.isRequired,
  links: PropTypes.arrayOf(PropTypes.any).isRequired
};

const Footer = () => {
  const t = useTranslations("navigation");

  const { companyTitle, companyName, companyCode } = useTranslations("contacts");

  const currentYear = new Date().getFullYear();

  return (
    <footer className={`bp-section-alternative ${styles.footer}`}>
      <div className="bp-container">
        <div className={styles.content}>
          <div className={styles.mainContacts}>
            <img src={logoImg} className={styles.logo} height="48" alt="" />

            <div className={styles.address}>
              <p>
                {companyTitle} {companyName}
              </p>
              <address>{CONTACT.address}</address>
              <p>
                {companyCode}
                :&nbsp;
                {CONTACT.companyCode}
              </p>
            </div>
            <SocialLinks className={styles.social} linkClassName={styles.socialLink} />
          </div>

          <div className={styles.contactUs}>
            <p className={styles.contactUsHeading}>{t.contactUs}:</p>
            <p className={styles.phone}>
              <a href={`tel:${CONTACT.phone}`} className="bp-link bp-accent bp-between">
                <IconPhone className="bp-fill bp-2" />
                &nbsp;
                {CONTACT.phone}
              </a>
            </p>
            <p className={styles.email}>
              <a href={`mailto:${CONTACT.email}`} className="bp-link bp-accent bp-between">
                <IconMail className="bp-fill bp-2" />
                &nbsp;
                {CONTACT.email}
              </a>
            </p>
          </div>
        </div>
      </div>

      <div className={`bp-container ${styles.copyright}`}>
        <p className="bp-between">
          <LocalizedLink to="/privacy-policy" className={`bp-link ${styles.privacyLink}`}>
            {t.privacyPolicy}
          </LocalizedLink>
          <span>
            <time dateTime={currentYear}>{currentYear}</time>
            &nbsp;©&nbsp;
            {companyName}
          </span>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
